import React from 'react'

import 'App.css'
import { CartContainer } from 'features/cart/components/CartContainer'

const CartPage = () => {
  console.log(123)

  return (
    <>
      <CartContainer />
    </>
  )
}

export default CartPage
